import React from 'react'

const EndSign = () => (
  <div
    className={`end max-w-md mx-auto my-16 text-soft-white text-2xl text-center`}
  >
    <span className={`mx-2`}>*</span>
    <span className={`mx-2`}>*</span>
    <span className={`mx-2`}>*</span>
  </div>
)

export default EndSign
