import React from 'react'
import { Disqus } from 'gatsby-plugin-disqus'
import ScrollableAnchor from 'react-scrollable-anchor'

const Comments = ({ config }) => {
  return (
    <ScrollableAnchor id={`comments`}>
      <div className={`max-w-lg mx-auto mt-0 mb-24 shadow-1 p-6 bg-white`}>
        <Disqus config={config} />
      </div>
    </ScrollableAnchor>
  )
}

export default Comments
