import React from 'react'
import { Link } from 'gatsby'

import TagList from './TagList'
import Date from './Date'
import Comments from './Comments'

import { widont } from '../utils/text'

class BlogMeta extends React.Component {
  constructor(props) {
    super(props)

    this.entry = this.props.entry
    this.next = this.props.next || null
    this.prev = this.props.prev || null
    this.tagBaseUri = this.props.tagBaseUri
      ? this.props.tagBaseUri
      : `/blog/tags/`
    this.disqusConfig = {
      // TODO: stop hardcoding URL
      url: `https://workingconcept.com/${this.entry.uri}`,
      title: this.entry.title,
    }
    this.state = {
      commentsActive: this.props.commentsActive || false,
    }
  }

  toggleComments() {
    this.setState(state => ({
      commentsActive: !state.commentsActive,
    }))
  }

  render() {
    return (
      <div className={`bg-soft-white clearfix relative`}>
        <div className={`max-w-md mx-auto clearfix py-24 px-6 md:px-0`}>
          <div className={`float-left w-1/2`}>
            <div className={`mb-4`}>
              <p className={`text-slate italic`}>Tagged</p>
              <TagList tags={this.entry.postTags} baseUri={this.tagBaseUri} />
            </div>

            <div className={`mb-4`}>
              <p className={`text-slate italic`}>
                Updated{' '}
                <Date
                  date={this.entry.dateUpdated}
                  format="M/D/YY \a\t h:mma"
                />
              </p>
            </div>
            {this.entry.commentsEnabled && (
              <p className={`comment-toggle`}>
                <button
                  className={`plain ${
                    this.state.commentsActive ? ' hidden' : ''
                  }`}
                  onClick={e => this.toggleComments(e)}
                >
                  Show Comments
                </button>
              </p>
            )}
          </div>
          <div className={`float-left w-1/2`}>
            {(this.next || this.prev) && (
              <nav className={`next-prev`}>
                {this.next && (
                  <p>
                    <span className={`text-slate italic block`}>
                      Next Post:
                    </span>
                    <Link
                      to={`/${this.next.uri}`}
                      className={`next`}
                      title={`Next Post: ${this.next.title}`}
                    >
                      {widont(this.next.title)}
                    </Link>
                  </p>
                )}
                {this.prev && (
                  <p className={this.next ? `mt-3` : ``}>
                    <span className={`text-slate italic block`}>
                      Previous Post:
                    </span>
                    <Link
                      to={`/${this.prev.uri}`}
                      className={`prev`}
                      title={`Next Post: ${this.prev.title}`}
                    >
                      {widont(this.prev.title)}
                    </Link>
                  </p>
                )}
              </nav>
            )}
          </div>
        </div>
        {this.state.commentsActive && <Comments config={this.disqusConfig} />}
      </div>
    )
  }
}

export default BlogMeta
