import React from 'react'

import Images from './blocks/Images'
import PostBlocks from './PostBlocks'

const BlogContent = ({ entry }) => {
  return (
    // .blog-post-content is used for JSON-LD's `speakable` attribute
    <article className={`blog-post-content`}>
      {entry.postImage && (
        <Images assets={entry.postImage} containerWidth="wide" />
      )}
      <PostBlocks blocks={entry.postBlocks} />
    </article>
  )
}

export default BlogContent
