import React from 'react'
import { slugify } from '../utils/text'
import { throttle } from 'lodash'

class BlogSubnav extends React.Component {
  constructor(props) {
    super(props)
    this.items = props.items
    this.slugs = this.getItemSlugs()
    this.state = {
      shouldDisplay: false,
      seen: [],
    }

    this.offEvent = this.offEvent.bind(this)
    this.onEvent = this.onEvent.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  updateState() {
    let mostRecentIndex = -1
    const scrollTop = this.getScrollTop()

    for (let index = 0; index < this.slugs.length; index++) {
      const slug = this.slugs[index]
      const elementY = this.getItemY(slug)

      if (elementY !== -1 && scrollTop > elementY) {
        // closest past item
        mostRecentIndex = index
      }
    }

    // remove unseen
    const seen =
      mostRecentIndex > -1 ? this.slugs.slice(0, mostRecentIndex + 1) : []

    this.setState({
      seen: seen,
      shouldDisplay: seen.length > 0
    })
  }

  componentDidMount() {
    this.onEvent()
    this.updateState()
  }

  componentWillUnmount() {
    this.offEvent()
  }

  offEvent() {
    window.removeEventListener('scroll', throttle(this.handleScroll, 250))
  }

  onEvent() {
    window.addEventListener('scroll', throttle(this.handleScroll, 250))
  }

  handleScroll(event) {
    if (this.slugs) {
      this.updateState();
    }
    //console.log(event)
  }

  getScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop
  }

  getBottomY(selector) {
    const element = document.querySelector(selector)

    if (element) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const elementRect = element.getBoundingClientRect()
      const elementY = elementRect.bottom + scrollTop
      return Math.round(elementY)
    }

    return 0
  }

  getItemSlugs() {
    let slugs = []

    this.items.forEach(item => {
      slugs.push(slugify(item.heading))
    })

    return slugs
  }

  getItemY(slug) {
    const element = document.querySelector('#' + slug)

    if (element) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const elementRect = element.getBoundingClientRect()
      const elementY = elementRect.top + scrollTop
      return Math.round(elementY)
    }

    return -1
  }

  getClass(slugIndex) {

    const seenIndex = this.state.seen.indexOf(this.slugs[slugIndex])

    if (seenIndex === this.state.seen.length - 1) {
      return 'opacity-100';
    }

    return seenIndex === -1 ? 'opacity-50' : 'opacity-25'
  }

  render() {
    return (
      <div
        className={`blog-subnav fixed right-0 items-center content-center justify-center mb-10 font-sans text-sm w-48 pr-6 ${
          this.state.shouldDisplay ? 'hidden xl:block fade-in' : 'hidden xl:hidden'
        }`}
        style={{ top: '50%', transform: 'translate(0, -50%)', zIndex: 0 }}
      >
        <ul>
          {this.items.map((item, index) => {
            return (
              <li
                key={item.heading}
                className={`py-1 transition-all ${this.getClass(index)}`}
              >
                <a href={`#${this.slugs[index]}`}>{item.heading}</a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default BlogSubnav
