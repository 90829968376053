//import { Link } from 'gatsby'
import React from 'react'

import { widont } from '../utils/text'

const TitleBlock = ({
  title,
  meta = '',
  subtitle = '',
  rule = false,
  headingElement = 'h1',
  titleMarkup = '',
  metaMarkup = '',
  align = `center`,
}) => {
  const size = headingElement === 'h1' ? '5xl' : '4xl'
  const HeadingTag =
    headingElement + (titleMarkup !== `` ? ` ` + titleMarkup : ``)

  const alignmentOptions = {
    left: `text-left`,
    center: `text-center`,
    right: `text-right`,
  }

  //let MetaTag;

  if (meta !== '') {
    // TODO: incorporate metaMarkup
    //const MetaTag = `p`;
  }

  return (
    <div className={`${alignmentOptions[align]}`}>
      <HeadingTag
        className={
          `text-` + size + ` font-bold px-4 max-w-md mx-auto pb-2 leading-none`
        }
        dangerouslySetInnerHTML={{ __html: widont(title) }}
      ></HeadingTag>

      {meta !== '' && (
        <p
          className={`font-sans pt-0 text-sm max-w-md mx-auto ${alignmentOptions[align]} text-light-slate`}
        >
          {meta}
        </p>
      )}

      {subtitle !== '' && (
        <p className={`text-light-slate max-w-lg px-8 pt-6 mx-auto`}>
          {subtitle}
        </p>
      )}

      {rule && (
        <div
          className={`mx-auto border-t border-soft-white opacity-50 w-12 mt-8 mb-6`}
        ></div>
      )}
    </div>
  )
}

export default TitleBlock
