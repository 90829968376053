import React from 'react'
import { graphql } from 'gatsby'
import readingTime from 'reading-time'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import TitleBlock from '../../components/TitleBlock'
import EndSign from '../../components/EndSign'
import BlogMeta from '../../components/BlogMeta'
import BlogContent from '../../components/BlogContent'
import BlogSubnav from '../../components/BlogSubnav'

import { formatDate } from '../../utils/text'
import { getMetaImageUrl } from '../../utils/images'

const BlogPost = props => {
  const entry = props.data.craftGql.entries[0]
  const defaultMetaImage = props.data.craftGql.globalSets[0].metaImage[0]
  const { previous, next } = props.pageContext
  const hash = props.location.hash || ``
  const stats = getStats(entry)
  const anchoredHeadings = getAnchoredHeadings(entry)
  const useSubnav = anchoredHeadings.length > 3

  let metaImage = entry.postImage.length
    ? entry.postImage[0]
    : entry.metaImage[0]

  if (!metaImage) {
    metaImage = defaultMetaImage
  }

  const schemaOrg = JSONLD(entry, metaImage, stats)

  return (
    <Layout>
      <SEO
        title={entry.title}
        description={entry.metaDescription}
        image={metaImage}
        structuredData={schemaOrg}
        cardSize={`large`}
      />
      <TitleBlock
        title={entry.title}
        meta={
          <>
            {formatDate(entry.postDate, `MMMM D, YYYY`)}{' '}
            <span
              className={`ml-3 ${
                stats.minutes > 15 ? 'text-orange' : 'text-light-slate'
              }`}
            >
              <span
                className={`inline-block relative mr-1`}
                style={{ width: `1rem`, height: `1rem`, top: `3px` }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`max-w-full h-auto`}
                >
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                </svg>
              </span>
              {stats.text}
            </span>
          </>
        }
        rule={!entry.postImage}
      />
      {useSubnav && <BlogSubnav items={anchoredHeadings} />}
      <BlogContent entry={entry} />
      <EndSign />
      <BlogMeta
        entry={entry}
        next={next}
        prev={previous}
        commentsActive={
          hash.includes(`#disqus_thread`) || hash.includes(`#comments`)
        }
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    craftGql {
      entries(section: "blog", slug: [$slug], limit: 1, status: null) {
        id
        title
        uri
        postDate
        dateCreated
        dateUpdated
        author {
          fullName
          photo {
            url
            width
            height
            extension
            ... on CraftGQL_headshots_Asset {
              dominantColor
            }
          }
          ... on CraftGQL_User {
            blogBio
          }
        }
        ... on CraftGQL_blog_blog_Entry {
          metaDescription
          metaImage {
            url
            focalPoint
          }
          postImage {
            ...BlogAsset
          }
          commentsEnabled
          postTags {
            title
            slug
          }
          postBlocks {
            ...PostBlocks
          }
        }
      }
      globalSets(handle: "defaults") {
        ... on CraftGQL_defaults_GlobalSet {
          metaImage {
            url
            focalPoint
          }
        }
      }
    }
  }
`

/**
 * Collect content like an animal, lump it together, and calculate.
 * @param {*} entry
 */
const getStats = entry => {
  let textContent = []

  if (entry.postImage && entry.postImage.length) {
    entry.postImage.forEach(asset => {
      textContent.push(asset.title)
      textContent.push(asset.altText)
      textContent.push(asset.caption)
      textContent.push(asset.credit)
    })
  }

  if (entry.postBlocks && entry.postBlocks.length) {
    entry.postBlocks.forEach(block => {
      textContent.push(block.body)
      textContent.push(block.text)
      textContent.push(block.snippet)
      textContent.push(block.caption)

      if (block.images && block.images.length) {
        block.images.forEach(asset => {
          textContent.push(asset.title)
          textContent.push(asset.altText)
          textContent.push(asset.caption)
          textContent.push(asset.credit)
        })
      }

      if (block.heading) {
        textContent.push(block.heading)
      }

      if (block.table) {
        textContent.push(block.table.table)
      }

      textContent.push(block.data)
      textContent.push(block.quote)
      textContent.push(block.attribution)
      textContent.push(block.role)
    })
  }

  return readingTime(textContent.join(' '))
}

const getAnchoredHeadings = entry => {
  return entry.postBlocks.filter(block => {
    return block.typeHandle === 'anchoredHeading'
  })
}

const JSONLD = (entry, metaImage, stats) => {
  let tags = []
  entry.postTags.forEach(tag => {
    tags.push(tag.title)
  })

  // TODO: add this first `Blog` item to listing page
  // TODO: add sameAs for docs
  return [
    {
      '@type': 'Blog',
      name: 'Working Concept Blog',
      description: 'Design and development notes from the field.',
      publisher: {
        '@id': 'https://workingconcept.com/#identity',
      },
      copyrightHolder: {
        '@id': 'https://workingconcept.com/#identity',
      },
      // "potentialAction": {
      //     "@type": "SearchAction",
      //     "target": {{ (siteUrl ~ 'blog/search?q={keyword}') | json_encode | raw }},
      //     "query-input": "required name=keyword"
      // },
      url: `https://workingconcept.com/blog`,
    },
    {
      '@type': 'BlogPosting',
      headline: entry.title,
      image: getMetaImageUrl(metaImage),
      keywords: `${tags.join(`,`)}`,
      wordCount: stats.words.toString(),
      timeRequired: `PT${Math.round(stats.minutes)}M`,
      publisher: {
        '@id': 'https://workingconcept.com/#identity',
      },
      url: `https://workingconcept.com/${entry.uri}`,
      mainEntityOfPage: `https://workingconcept.com/${entry.uri}`,
      // TODO: figure out how to handle footnote refs before enabling `speakable`
      // speakable: {
      //   '@type': `SpeakableSpecification`,
      //   cssSelector: [`.blog-post-content`],
      // },
      datePublished: formatDate(entry.postDate, `YYYY-MM-DD`),
      dateCreated: formatDate(entry.dateCreated, `YYYY-MM-DD`),
      dateModified: formatDate(entry.dateUpdated, `YYYY-MM-DD`),
      description: entry.metaDescription,
      author: {
        '@type': `Person`,
        name: entry.author.fullName,
      },
    },
  ]
}

export default BlogPost
